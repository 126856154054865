var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"organizations-table fill-height",attrs:{"tile":"","dark":"","color":"black"}},[_c('v-data-table',{staticClass:"organizations-table__data-table",attrs:{"headers":_vm.headers,"items":_vm.rankedOrganizations,"loading":_vm.loading,"search":_vm.searchQuery,"item-key":"id","dark":"","color":"black","height":"100vh","items-per-page":-1,"footer-props":{
      'items-per-page-options': [-1],
      'items-per-page-text': 'Show all'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center pa-4"},[_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":"","dark":"","color":"black"}},[_c('v-text-field',{staticClass:"ma-3",attrs:{"label":"Search your organization","placeholder":"Search by title","prepend-inner-icon":"mdi-magnify","clearable":true,"hide-details":"","outlined":"","rounded":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchOrganizations.apply(null, arguments)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-text-field',{staticClass:"ma-3",attrs:{"label":"Enter your email","placeholder":"user@company.com","prepend-inner-icon":"mdi-email","clearable":true,"hide-details":"","rounded":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchByEmail.apply(null, arguments)}},model:{value:(_vm.emailInput),callback:function ($$v) {_vm.emailInput=$$v},expression:"emailInput"}}),_c('v-select',{staticClass:"ma-3",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.sortOptions,"label":"Sort by","hide-details":"","outlined":"","rounded":"","dark":""},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"x-large":"","rounded":"","outlined":"","dark":""},on:{"click":_vm.refreshData}},[_vm._v(" Refresh "),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")])],1)],1)],1)]},proxy:true},{key:"item.domain",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","href":("https://" + (_vm.formatDomain(item.domain))),"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-web")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formatDomain(item.domain)))])])]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:_vm.getOrganizationHighlightClass(item)},[_c('h3',{staticClass:"text-h6 font-weight-bold mb-0"},[_vm._v(" "+_vm._s(item.title)+" "),(_vm.isTopFive(item))?_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":"","x-small":"","color":"yellow","depressed":"","text-color":"yellow"}},[_vm._v(" TOP "+_vm._s(item.rank)+" ")]):_vm._e(),(_vm.isHighlyEngaged(item) && !_vm.isTopFive(item))?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":"purple","outlined":"","depressed":"","text-color":"purple"}},[_vm._v(" MOST ENGAGED ")]):_vm._e()],1)])]}},{key:"item.organizationJoinLink",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.organizationJoinLink && item.members > 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({class:hover ? 'white--text font-weight-bold' : '',attrs:{"small":"","rounded":"","depressed":"","outlined":!hover,"color":hover ? 'green darken-2' : 'grey',"target":"_blank","href":item.organizationJoinLink}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":hover ? 'white' : 'green'}},[_vm._v(" "+_vm._s(hover ? 'mdi-arrow-right' : 'mdi-check-circle')+" ")]),_vm._v(" "+_vm._s(hover ? 'Join' : 'Claimed')+" ")],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("This profile is already claimed. Manage your organization and access insights.")])]):(item.users > 0 && item.members === 0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({class:hover ? 'white--text font-weight-bold' : '',attrs:{"small":"","rounded":"","depressed":"","outlined":!hover,"color":hover ? 'orange' : 'grey',"target":"_blank","href":item.organizationJoinLink}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":hover ? 'white' : 'orange'}},[_vm._v(" "+_vm._s(hover ? 'mdi-arrow-right' : 'mdi-alert-circle')+" ")]),_vm._v(" "+_vm._s(hover ? 'Claim Now' : 'Unclaimed')+" ")],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("You're a user of this platform but not yet a member of your organization. Claim your profile to access features and manage your organization.")])]):(item.organizationJoinLink)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({class:hover ? 'white--text font-weight-bold' : '',attrs:{"small":"","rounded":"","depressed":"","outlined":!hover,"color":hover ? 'red' : 'grey',"target":"_blank","href":item.organizationJoinLink}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":hover ? 'white' : 'red'}},[_vm._v(" "+_vm._s(hover ? 'mdi-arrow-right' : 'mdi-alert-circle')+" ")]),_vm._v(" "+_vm._s(hover ? 'Claim Now' : 'Unclaimed')+" ")],1)]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("This profile is unclaimed. Claim it to take control, access insights, and unlock features for your organization.")])]):_vm._e()],1)]}},{key:"item.rank",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:_vm.getRankColorClass(item)},[_vm._v("#"+_vm._s(item.rank))])]}},{key:"item.participants",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-account-group")]),_c('h3',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(item.participants))])],1)]}}],null,true)},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.getParticipantsComparison(item)))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getPercentageFromAverage(item))+"% "+_vm._s(_vm.isAboveAverage(item) ? 'above' : 'below')+" average ")])])])]}},{key:"item.programs",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","small":""}},[_vm._v("mdi-sprout")]),_c('h3',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(item.programs))])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }