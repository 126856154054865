















































































































































































































































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import { fetchOrganizations, Organization } from '@/services/airtable';

export default defineComponent({
  name: 'OrganizationsTable',

  setup() {
    const allOrganizations = ref<Organization[]>([]);
    const organizations = ref<Organization[]>([]);
    const selectedOrganizations = ref<Organization[]>([]);
    const loading = ref(false);
    const searchQuery = ref('');
    const emailInput = ref('');
    const sortBy = ref('rank');
    const sortOptions = [
      { text: 'Rank', value: 'rank' },
      { text: 'Participants', value: 'participants' },
      { text: 'Users', value: 'users' },
      { text: 'Members', value: 'members' },
      { text: 'Programs', value: 'programs' },
      { text: 'Claim Status', value: 'claimStatus' }
    ];

    function getClaimStatusPriority(org: Organization): number {
      if (org.members > 0 && org._id) {
        return 3; // Claimed with green check
      }
      if (org.users > 0 && org.members === 0) {
        return 2; // Unclaimed with yellow alert
      }
      return 1; // Unclaimed with grey alert
    }

    const rankedOrganizations = computed(() => {
      const rankedAll = allOrganizations.value
        .slice()
        .sort((a, b) => b.participants - a.participants)
        .map((org, index) => ({
          ...org,
          rank: index + 1
        }));

      let displayOrgs = organizations.value.map(org => {
        const rankedOrg = rankedAll.find(r => r.id === org.id);
        return {
          ...org,
          rank: rankedOrg?.rank || 0
        };
      });

      if (sortBy.value === 'claimStatus') {
        displayOrgs = displayOrgs.sort((a, b) => {
          const statusDiff = getClaimStatusPriority(b) - getClaimStatusPriority(a);
          if (statusDiff !== 0) return statusDiff;

          return b.members - a.members;
        });
      } else if (sortBy.value !== 'rank') {
        displayOrgs = displayOrgs.sort((a, b) => b[sortBy.value] - a[sortBy.value]);
      }

      return displayOrgs;
    });

    const headers = [
      {
        text: 'Rank',
        value: 'rank',
        align: 'center',
        width: '10%'
      },
      {
        text: 'Organization',
        value: 'title',
        align: 'start',
        width: '45%'
      },
      {
        text: 'Participants',
        value: 'participants',
        align: 'center',
        width: '10%'
      },

      {
        text: 'Claim Status',
        value: 'organizationJoinLink',
        align: 'start',
        width: '10%'
      },
      {
        text: 'Programs',
        value: 'programs',
        align: 'center',
        width: '10%'
      },
      {
        text: 'Domain',
        value: 'domain',
        align: 'center',
        width: '10%'
      },
      {
        text: 'Members',
        value: 'members',
        align: 'center',
        width: '10%'
      },
      {
        text: 'Users',
        value: 'users',
        align: 'center',
        width: '10%'
      }
    ];

    function extractDomainFromEmail(email: string): string {
      try {
        return email.split('@')[1].toLowerCase();
      } catch {
        return '';
      }
    }

    function formatDomain(domain: any): string {
      if (!domain) return '';
      if (Array.isArray(domain)) {
        return domain[0]?.replace(/[[\]"]/g, '') || '';
      }
      if (typeof domain === 'string') {
        return domain.replace(/[[\]"]/g, '');
      }
      return String(domain);
    }

    async function searchByEmail() {
      if (!emailInput.value) {
        organizations.value = allOrganizations.value;
        return;
      }

      const domain = extractDomainFromEmail(emailInput.value);
      if (!domain) return;

      loading.value = true;
      try {
        organizations.value = allOrganizations.value.filter(org => {
          const orgDomain = formatDomain(org.domain);
          return orgDomain.toLowerCase() === domain.toLowerCase();
        });
      } catch (error) {
        console.error('Failed to search organizations by domain:', error);
      } finally {
        loading.value = false;
      }
    }

    async function loadOrganizations() {
      loading.value = true;
      try {
        allOrganizations.value = await fetchOrganizations();
        organizations.value = allOrganizations.value;
      } catch (error) {
        console.error('Failed to load organizations:', error);
      } finally {
        loading.value = false;
      }
    }

    async function searchOrganizations() {
      if (!searchQuery.value) {
        organizations.value = allOrganizations.value;
        return;
      }

      organizations.value = allOrganizations.value.filter(org =>
        org.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    }

    async function refreshData() {
      searchQuery.value = '';
      emailInput.value = '';
      await loadOrganizations();
    }

    const isTopFive = org => org.rank <= 5;

    const isHighlyEngaged = org => {
      const avgParticipants = computed(() => {
        const total = allOrganizations.value.reduce((sum, org) => sum + org.participants, 0);
        return total / allOrganizations.value.length;
      });
      return org.participants > avgParticipants.value * 2; // 2x above average
    };

    const getOrganizationHighlightClass = org => {
      const classes = ['pa-2', 'rounded'];

      if (isTopFive(org)) {
        classes.push('org-highlight-gold');
      } else if (isHighlyEngaged(org)) {
        classes.push('org-highlight-purple');
      }

      return classes;
    };

    const getParticipantsComparison = org => {
      const avgParticipants = computed(() => {
        const total = allOrganizations.value.reduce((sum, org) => sum + org.participants, 0);
        return Math.round(total / allOrganizations.value.length);
      });

      return `${org.participants.toLocaleString()} participants`;
    };

    const getPercentageFromAverage = org => {
      const avgParticipants = computed(() => {
        const total = allOrganizations.value.reduce((sum, org) => sum + org.participants, 0);
        return total / allOrganizations.value.length;
      });

      const percentage = Math.round(
        ((org.participants - avgParticipants.value) / avgParticipants.value) * 100
      );
      return Math.abs(percentage);
    };

    const isAboveAverage = org => {
      const avgParticipants = computed(() => {
        const total = allOrganizations.value.reduce((sum, org) => sum + org.participants, 0);
        return total / allOrganizations.value.length;
      });

      return org.participants > avgParticipants.value;
    };

    const getRankColorClass = org => {
      const classes = ['font-weight-bold'];

      if (isTopFive(org)) {
        classes.push('yellow--text');
      } else if (isHighlyEngaged(org)) {
        classes.push('purple--text');
      } else {
        classes.push('grey--text');
      }

      return classes;
    };

    onMounted(loadOrganizations);

    return {
      organizations,
      selectedOrganizations,
      loading,
      searchQuery,
      emailInput,
      headers,
      searchOrganizations,
      searchByEmail,
      refreshData,
      formatDomain,
      rankedOrganizations,
      sortBy,
      sortOptions,
      getClaimStatusPriority,
      isTopFive,
      isHighlyEngaged,
      getOrganizationHighlightClass,
      getParticipantsComparison,
      getPercentageFromAverage,
      isAboveAverage,
      getRankColorClass
    };
  }
});
